import instance from '../instance';
import PaginationHelper from './helper/paginationHelper';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';

const baseUrl = 'hierarchy-nodes/';

const contacts = {
  /**
   * @param {string} hierarchyNodeId
   * @param {Object} pagination
   */
  list(hierarchyNodeId, pagination) {
    const params = PaginationHelper(pagination);
    return instance.get(`${baseUrl}${hierarchyNodeId}/contacts`, { params });
  },
};

const contactQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @param {Object} params
     * @property {string} hierarchyNodeId
     * @property {Object} pagination
     */
    getContacts: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() =>
          contacts.list(params.hierarchyNodeId, params.pagination),
        );
      },
      providesTags: result =>
        result?.results
          ? result.results.map(contact => ({
              type: tagTypes.contact,
              id: contact.id,
            }))
          : [tagTypes.contact],
    }),
  }),
});

export const { useGetContactsQuery } = contactQueries;

export default contacts;
