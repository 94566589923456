import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    color: '#FFFFFF',
    backgroundColor: '#00e094',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#00e094',
    },
  },
};

export default withStyles(styles)(IconButton);
