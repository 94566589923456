import {
  extendedColors,
  neutralColors,
  systemColors,
  themeColors,
} from '../colors';

const ChipStyles = {
  MuiChip: {
    root: {
      fontWeight: 700,
      fontSize: '0.625rem',
      '&.big': {
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: systemColors.infoRegular,
        color: systemColors.infoRegular,
        fontSize: '0.75rem',
      },
      '&.active': {
        backgroundColor: extendedColors.greenBg,
        color: extendedColors.greenDark,
      },
      '&.error': {
        background: systemColors.errorBg,
        color: systemColors.errorRegular,
      },
      '&.inactive': {
        backgroundColor: neutralColors.neutral100,
        color: neutralColors.neutral500,
      },
      '&.info': {
        backgroundColor: themeColors.secondaryBg,
        color: systemColors.infoRegular,
      },
      '&.success': {
        background: systemColors.successBg,
        color: systemColors.successRegular,
      },
      '&.imported': {
        background: systemColors.successBg,
        color: systemColors.successRegular,
      },
      '&.partial': {
        background: systemColors.warningBg,
        color: systemColors.warningRegular,
      },
      '&.border-sm': {
        borderRadius: '0.5rem',
      },
      '&.width-fit': {
        width: 'fit-content',
      },
      '& .MuiChip-avatar': {
        color: 'inherit',
      },
      '&.full-height': {
        height: '100%',
      },
    },
  },
};

export default ChipStyles;
