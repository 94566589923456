import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TableCell, TableRow } from '@material-ui/core';
import UsePagination from '@spider:src/hooks/UsePagination';
import { formatDate } from '@src/helpers/DateHelper';
import { Participant } from './components/Participant';
import { TransactionType } from './components/TransactionType';
import { Drawer } from '@mui/material';
import { SubType } from './components/SubType';
import { useLazyGetPointsTransactionsListQuery } from '@src/data/api/endpoints/pointsTransactions';
import { DataList } from '../DataList';
import { OrderDetails } from '../OrdersList/components/OrderDetails';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { useSelectDataFromRole } from '@src/Spider/hooks/useSelectDataFromRole';
import { AuthorizedRolesWrapper } from '../AuthorizedRolesWrapper';
import { UserRoles } from '@src/Spider/enums';
import { EmptyTableView } from '../EmptyTableView';
import EmptyTableImage from '../../assets/img/empty-table-participants.png';
import { Amount } from '../Amount';

/**
 * @param {{queryParams: import("@async-calls/pointsTransactions").PointsListParams}} props
 */
export const PointsList = ({ queryParams }) => {
  const intl = useIntl();
  const { open, onOpen, onClose } = useDisclosure();
  const [selectedOrderUuid, setSelectedOrderUuid] = useState('');
  const [fetch, { data, isFetching, isLoading, error }] =
    useLazyGetPointsTransactionsListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = useSelectDataFromRole({
    super_administrator: ['date', 'participant', 'type', 'sub_type', 'points'],
    administrator: ['date', 'participant', 'type', 'sub_type', 'points'],
    collaborator: ['date', 'type', 'sub_type', 'points'],
    manager: ['date', 'type', 'sub_type', 'points'],
  }).map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.points.tabs.list.table.${el}`,
    }),
  }));

  const onOpenDrawer = transaction => {
    onOpen();
    setSelectedOrderUuid(transaction.source.uuid);
  };

  const onCloseDrawer = () => {
    onClose();
    setSelectedOrderUuid('');
  };

  const Row = React.memo(({ item }) => (
    <TableRow key={item.uuid}>
      <TableCell>
        {formatDate(item.date).replaceAll('/', '.')}
        <div style={{ fontSize: '10px' }}>
          {item.date.toDate().toLocaleString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
      </TableCell>
      <AuthorizedRolesWrapper roles={[UserRoles.ADMINISTRATOR]}>
        <TableCell>
          <Participant transaction={item} />
        </TableCell>
      </AuthorizedRolesWrapper>
      <TableCell>
        <TransactionType transaction={item} />
      </TableCell>
      <TableCell>
        <SubType transaction={item} onOpenDrawer={onOpenDrawer} />
      </TableCell>
      <TableCell>
        <Amount number={item.amount} />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <DataList
        columns={columns}
        data={data?.results}
        count={data?.count}
        RowComponent={Row}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        paginationCount={paginationCount}
        emptyState={
          <EmptyTableView
            imageSrc={EmptyTableImage}
            imageAlt='spider.points.tabs.list.table.no-data.image-alt'
            title='spider.points.tabs.list.table.no-data.title'
          />
        }
      />
      <Drawer anchor='right' open={open} onClose={onCloseDrawer}>
        <OrderDetails uuid={selectedOrderUuid} onClose={onCloseDrawer} />
      </Drawer>
    </>
  );
};
